<template>
  <div>
    <el-dialog title="提示" :visible.sync="needpay" :close-on-click-modal="false" :close-on-press-escape="false"
      :show-close="false" width="30%" center>
      <h3>尚未缴纳质保金，不可操作</h3>
      <p>您尚未缴纳质保金，上传商品将不可用，请缴纳质保金后再进行操作。</p>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="noPay">暂不缴纳</el-button>
        <el-button size="small" type="primary" @click="toPay">立即缴纳</el-button>
      </span>
    </el-dialog>
    <x-dialog :proxy="selectDeliveryArea">
      <div style="position: relative;">
        <div class="el-tree-box">
          <el-tree :empty-text="index === 1 ? '暂无数据' : ''" v-for="(item, index) in areaData" :key="index"
            style="width:30%" :data="item" :accordion="true" show-checkbox :props="defaultProps"
            :render-content="renderContent" node-key="id" ref="tree" @check-change="handleCheckChange"></el-tree>
        </div>
      </div>
      <template slot="footer-left">
        <div class="el-tree-bottom">
          <el-checkbox v-model="checkedAll" @change="setCheckedNodes">全选</el-checkbox>
          <div class="el-tree-bottom">
            <div style="color:#bbbbbb;margin-right:10px;">已选择{{ checkedNodesNum }}个区域</div>
            <el-button :disabled="checkedNodesNum === 0" type="primary" size="small"
              @click="submitDeliveryArea">提交</el-button>
          </div>
        </div>
      </template>
    </x-dialog>
    <div v-loading="loading" v-show="goodsper">
      <el-steps :active="activestep" finish-status="success" process-status="process" simple style="margin-top: 20px">
        <el-step title="1.选择商品品类"></el-step>
        <el-step title="2.编辑基本信息"></el-step>
        <el-step title="3.编辑商品详情"></el-step>
      </el-steps>
      <!--步骤1-->
      <div class="content-goods-publish" v-show="activestep === 0">
        <div class="goods-category">
          <ul v-if="categoryListLevel1 && categoryListLevel1.length > 0">
            <li v-for="(item, index) in categoryListLevel1" :class="{ activeClass: index == activeCategoryIndex1 }"
              @click="handleSelectCategory(item, index, 1)" :key="index">
              <span>{{ item.name }}</span>
              <span>》</span>
            </li>
          </ul>
          <ul v-if="categoryListLevel2 && categoryListLevel2.length > 0">
            <li v-for="(item, index) in categoryListLevel2" :class="{ activeClass: index == activeCategoryIndex2 }"
              @click="handleSelectCategory(item, index, 2)" :key="index">
              <span>{{ item.name }}</span>
              <span>》</span>
            </li>
          </ul>
          <ul v-if="categoryListLevel3 && categoryListLevel3.length > 0">
            <li v-for="(item, index) in categoryListLevel3" :class="{ activeClass: index == activeCategoryIndex3 }"
              @click="handleSelectCategory(item, index, 3)" :key="index">
              <span>{{ item.name }}</span>
              <span>》</span>
            </li>
          </ul>
        </div>
        <p class="current-goods-category">
          您当前选择的商品类别是：
          <span>{{ activeCategoryName1 }}</span>
          <span v-show="activeCategoryName2">> {{ activeCategoryName2 }}</span>
          <span v-show="activeCategoryName3">> {{ activeCategoryName3 }}</span>
        </p>
      </div>
      <!--步骤2-->
      <div class="content-goods-publish" v-show="activestep === 1">
        <!--商品详情-->
        <el-form :model="baseInfoForm" status-icon :rules="baseInfoFormRule" label-position="right" ref="baseInfoForm"
          label-width="120px" class="demo-ruleForm">
          <!--商品详情-->
          <!--基本信息-->
          <!-- <div class="base-info-item">
						<h4>基本信息</h4>
						<div>
							<el-form-item class="auth-info" label="拒绝原因" v-if="baseInfoForm.is_auth === 2">
								{{ baseInfoForm.auth_message }}
							</el-form-item>
							<el-form-item label="商品分类" style="width: 100%; text-align: left;">
								<span>{{ activeCategoryName1 }}</span>
								<span v-show="activeCategoryName2"> > {{ activeCategoryName2 }}</span>
								<span v-show="activeCategoryName3"> > {{ activeCategoryName3 }}</span>
								<span v-if="!activeCategoryName1" v-html="baseInfoForm.category_name"></span>
							</el-form-item>
							<el-form-item label="店铺商品分组" >
								<en-category-picker
									@changed="changeGoodsCateGory"
									:clearable='true'
									:defaultVal="baseInfoForm.shop_cat_id" />
							</el-form-item>
							<p class="goods-group-manager">
								商品可以从属于店铺的某个分组之下，店铺分组可以由 "商家中心 -> 商品管理 -> 分组管理" 中自定义
							</p>
							<el-form-item label="商品品牌" >
								<el-select
									v-model="baseInfoForm.brand_id"
									filterable
									@change="changeGoodsBrand"
									clearable
									placeholder="请选择">
									<el-option
										v-for="item in brandList"
										:key="item.brand_id"
										:label="item.name"
										:value="item.brand_id">
									</el-option>
								</el-select>
							</el-form-item>
						</div>
          </div>-->
          <!--商品信息-->
          <div class="base-info-item">
            <h4>商品信息</h4>
            <div>
              <el-form-item label="商品名称" prop="goods_name" class="goods-name-width">
                <el-input v-model.trim="baseInfoForm.goods_name" @change="() => {
      baseInfoForm.goods_name = baseInfoForm.goods_name.trim();
    }
      " :maxlength="255" :minlength="3" placeholder="请输入商品名称"></el-input>
              </el-form-item>
              <el-form-item label="商品品牌">
                <el-select v-model="baseInfoForm.brand_id" filterable @change="changeGoodsBrand" clearable
                  placeholder="请选择">
                  <el-option v-for="item in brandList" :key="item.brand_id" :label="item.name"
                    :value="item.brand_id"></el-option>
                </el-select>
              </el-form-item>
              <!-- <el-form-item label="商品编号" prop="sn">
								<el-input v-model="baseInfoForm.sn" :maxlength="20"></el-input>
              </el-form-item>-->
              <el-form-item label="商品图片" prop="goods_gallery" style="width: 90%;text-align: left;">
                <el-upload class="avatar-uploader goods-images" :action="`${MixinUploadApi}?scene=goods`"
                  list-type="picture-card" multiple :limit="5" :on-exceed="handleExceed" :file-list="goodsGalleryList"
                  :on-preview="handlePictureCardPreview" :before-upload="beforeAvatarUpload" :on-remove="handleRemove"
                  :on-success="handleSuccess">
                  <!-- <span style="display: none;"  v-model="baseInfoForm.goods_gallery"></span> -->
                  <i class="el-icon-plus avatar-uploader-icon"></i>
                  <div slot="tip" class="el-upload__tip">建议尺寸：640*640像素，大小不超1MB，可拖拽调整位置，最多上传5张图。</div>
                </el-upload>
                <el-dialog :visible.sync="dialogImage">
                  <img width="100%" :src="dialogImageUrl" alt />
                </el-dialog>
              </el-form-item>

              <!-- <el-form-item
                  label-width="100px"
                  label="会员价"
                  prop="price"
                  :maxlength="10"
                  :inline-message="true"
                >
                  <el-input
                    placeholder="请输入会员价"
                    v-model="baseInfoForm.price"
                  >
                    <template slot="prepend"
                      >¥</template
                    >
                  </el-input>
                </el-form-item>-->


              <!-- <el-form-item label="商品重量" prop="weight" :maxlength="10">
								<el-input placeholder="请输入商品重量" v-model="baseInfoForm.weight">
									<template slot="prepend">kg</template>
								</el-input>
              </el-form-item>-->

            </div>
          </div>
          <!--商品规格-->
          <div class="base-info-item">
            <h4>商品规格</h4>
            <div>
              <el-form-item label="商品规格" style="width: 90%;text-align: left;">
                <!--规格选择器-->
                <en-sku-selector :categoryId="baseInfoForm.category_id" :goodsId="activeGoodsId" goodsSn=""
                  :productSn="productSn" :isEditModel="currentStatus" :goodsSkuInfo="skuList" @finalSku="finalSku"
                  @set_showSpecifications="set_showSpecifications" :origin="originTable" />

                <!-- <en-sku-selector :categoryId="baseInfoForm.category_id" :goodsId="activeGoodsId"
                  :goodsSn="baseInfoForm.sn" :productSn="productSn" :isEditModel="currentStatus" :goodsSkuInfo="skuList"
                  @finalSku="finalSku" /> -->
              </el-form-item>
              <el-form-item v-if="!showSpecifications" label="商品价格">
                <el-form-item label-width="100px" label="供货价" prop="cost" :maxlength="10" :inline-message="true">
                  <!-- // 成本价 - > 供货价 -->
                  <el-input placeholder="请输入供货价" v-model="baseInfoForm.cost" style="width: 200px; margin-right: 10px">
                    <template slot="prepend">¥</template>
                  </el-input> <span style="font-size: 12px;color:rgb(93 86 86 / 63%)">(含一件代发运费的价格)</span>
                </el-form-item>
                <el-form-item label-width="100px" label="市场价" prop="mktprice" :maxlength="10" :inline-message="true">
                  <el-input placeholder="请输入市场价" style="width:200px" v-model="baseInfoForm.mktprice">
                    <template slot="prepend">¥</template>
                  </el-input>
                </el-form-item>
                <el-form-item label-width="100px" label="集采价" prop="original_price" :maxlength="10"
                  :inline-message="true">
                  <el-input placeholder="请输入集采价" style="width:200px" v-model="baseInfoForm.original_price">
                    <template slot="prepend">¥</template>
                  </el-input>
                </el-form-item>
              </el-form-item>
              <el-form-item v-if="!showSpecifications" label="总库存" prop="quantity" style="width: 20%;text-align: left;">
                <el-input v-model="baseInfoForm.quantity" :disabled="isEditQuantity"></el-input>
              </el-form-item>

            </div>
          </div>
          <div v-if="!showSpecifications" class="base-info-item" style="text-align:left">
            <h4>配送区域</h4>
            <div>
              <el-form-item style="width: 90%;position: relative;" label="配送区域" prop="ship_regions">
                <el-input disabled v-model="baseInfoForm.ship_regions" style="width:100%" size="large" type="textarea"
                  :rows="8"></el-input>
                <div class="displayBoard" v-html="baseInfoForm.ship_regions"></div>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" size="small" @click="setDistributionArea">{{ baseInfoForm.ship_regions ?
      '修改配送区域' : '配送区域设置' }}</el-button>
              </el-form-item>
            </div>
          </div>
          <!--商品详情-->
          <div class="base-info-item">
            <h4>商品详情</h4>
            <div>
              <el-form-item style="width: 90%;" label="商品详情" class="goods-intro">
                <UE ref="ue" :defaultMsg="baseInfoForm.intro"></UE>
              </el-form-item>
            </div>
          </div>
          <!--seo-->
          <!-- <div class="base-info-item">
						<h4>seo</h4>
						<div>
							<el-form-item label="seo标题">
								<el-input placeholder="最多60个字符" class="seo-text" @change="() => { baseInfoForm.page_title = baseInfoForm.page_title.trim() }" :maxlength="60"  v-model="baseInfoForm.page_title"></el-input>
							</el-form-item>
							<el-form-item label="seo关键字" >
								<el-input type="textarea" placeholder="最多200个字符" class="seo-text" rows="5" :maxlength="200" v-model="baseInfoForm.meta_keywords"></el-input>
							</el-form-item>
							<el-form-item label="seo描述">
								<el-input type="textarea" placeholder="最多500个字符" class="seo-text" rows="5" :maxlength="500" v-model="baseInfoForm.meta_description"></el-input>
							</el-form-item>
						</div>
          </div>-->
          <!--物流/其他-->
          <!-- <div class="base-info-item">
						<h4>物流/其他</h4>
						<div>
							<el-form-item label="运费" style="width: 50%;" prop="template_id">
								<el-radio-group v-model="baseInfoForm.goods_transfee_charge" @change="changeTplItem">
									<el-radio :label="1">卖家承担运费</el-radio>
									<el-radio :label="0">买家承担运费</el-radio>
								</el-radio-group>
								<el-select
									v-model="baseInfoForm.template_id"
									placeholder="请选择运费模板"
									v-show="baseInfoForm.goods_transfee_charge === 0 "
									@change="changeTpl">
									<el-option
										v-for="item in tplList"
										:key="item.id"
										:label="item.name"
										:value="item.id">
									</el-option>
								</el-select>
							</el-form-item>
						</div>
					</div>
          -->
          <!--兑换许可 自营店显示-->
          <!-- <div class="base-info-item">
					<div class="base-info-item" v-if="parseInt(shopInfo.self_operated) === 1">
						<h4>兑换许可</h4>
						<div>
							<el-form-item label="积分兑换">
								<el-switch
									v-model="isShowExchangeConfig"
									active-color="#13ce66"
									inactive-color="#ff4949"
									active-text="开启"
									inactive-text="关闭"
									@change="handleSwitchexchange">
								</el-switch>
							</el-form-item>
						</div>
          </div>-->
          <!--积分配置-->
          <div class="base-info-item" v-if="isShowExchangeConfig">
            <h4>积分配置</h4>
            <div v-if="baseInfoForm.exchange">
              <el-form-item label="兑换条件" style="width: 25%;" prop="exchange_money">
                <el-input placeholder="最多10个字符" :maxlength="10" v-model="baseInfoForm.exchange.exchange_money"
                  style="width: 100px;"></el-input>元
              </el-form-item>
              <el-form-item style="width: 25%;" prop="exchange_point">
                <el-input placeholder="最多10个字符" :maxlength="10" v-model.number="baseInfoForm.exchange.exchange_point"
                  style="width: 100px;"></el-input>积分
              </el-form-item>
              <!-- <el-form-item label="积分商品分类" >
								<el-select
									v-model="baseInfoForm.exchange.category_id"
									placeholder="请选择积分商品分类"
									@change="changeExangeCategory">
									<el-option
										v-for="item in exchangeGoodsCatrgoryList"
										:key="item.category_id"
										:label="item.name"
										:value="item.category_id">
									</el-option>
								</el-select>
              </el-form-item>-->
            </div>
          </div>
          <!--商品参数-->
          <!-- <el-collapse :value="collapseVal">
						<el-collapse-item
							v-for="paramsgroup in  goodsParams"
							v-if="paramsgroup"
							:title="paramsgroup.group_name"
							:key="paramsgroup.group_id"
							:name="paramsgroup.group_id">
							<el-form-item
								v-for="(goods_params_list, index) in baseInfoForm.goods_params_list"
								v-if="goods_params_list && goods_params_list.group_id && paramsgroup.group_id === goods_params_list.group_id"
								:key="index"
								:label="`${goods_params_list.param_name}`"
								:prop="'goods_params_list.' + index + '.param_value'"
								:rules="goods_params_list.required === 1 ? {required: true, message: `${goods_params_list.param_name}不能为空`, trigger: 'change' } : {}">
								<el-input
									placeholder="长度为最多100个字符"
									maxlength="100"
									v-if="goods_params_list.param_type === 1"
									v-model="goods_params_list.param_value" >
								</el-input>
								<el-select
									v-if="goods_params_list.param_type === 2"
									v-model="goods_params_list.param_value"
									placeholder="请选择">
									<el-option
										v-for="(option) in goods_params_list.option_list"
										:key="option"
										:label="option"
										:value="option">
									</el-option>
								</el-select>
							</el-form-item>
						</el-collapse-item>
          </el-collapse>-->
        </el-form>
      </div>
      <!--步骤3-->
      <div class="content-goods-publish" v-if="activestep === 2">
        <el-form :model="baseInfoForm" label-position="right" ref="baseInfoFormIntro" label-width="120px"
          class="demo-ruleForm">
          <el-form-item label="商品描述" class="goods-intro">
            <UE ref="ue" :defaultMsg="baseInfoForm.intro"></UE>
          </el-form-item>
        </el-form>
      </div>
      <!--底部-->
      <div class="footer">
        <el-button-group>
          <el-button type="primary" size="small" @click="pre"
            v-if="activestep === 1 || activestep === 2">上一步</el-button>
          <el-button type="primary" size="small" @click="next" v-if="activestep === 0">下一步</el-button>
          <el-button :loading="createGoodsButtonLoading" type="primary" size="small" @click="aboveGoods"
            v-if="activestep === 1 || activestep === 2">确认创建</el-button>
          <!-- <el-button
						type="primary"
						 size="small"
						@click="handleUnderGoods"
						v-if="currentStatus === 1 && ( activestep === 1 || activestep === 2) && baseInfoForm.market_enable === 1"
					>下架</el-button>
					<el-button
						type="primary"
						 size="small"
						@click="saveDraft"
						v-if="(currentStatus != 1 && activestep != 0)"
          >保存草稿</el-button>-->
        </el-button-group>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import * as API_goods from "@/api/goods";
import * as API_express from "@/api/expressMould";
import * as API_goodsCategory from "@/api/goodsCategory";
import { CategoryPicker, SkuSelector, UE } from "@/components";
import { RegExp } from "@/../ui-utils";
import Sortable from "sortablejs";
import xDialog from "@/components/x-dialog/x-dialog";
import { $xDialog } from "@/components/x-dialog/dialog.proxy";
import { TransferTree } from "@/components";
export default {
  name: "goodsPublish",
  components: {
    xDialog,
    [TransferTree.name]: TransferTree,
    [CategoryPicker.name]: CategoryPicker,
    [SkuSelector.name]: SkuSelector,
    [UE.name]: UE
  },
  computed: {
    ...mapGetters(["shopInfo"])
  },
  data () {
    const checkSn = (rule, value, callback) => {
      if (!value) {
        callback(new Error("商品编号不能为空"));
      } else if (!/^[a-zA-Z0-9_\\-]+$/g.test(value)) {
        callback(new Error("请输入数字、字母、下划线或者中划线"));
      } else {
        callback();
      }
    };

    const checkMarket = (rule, value, callback) => {
      if (!value) {
        callback(new Error("市场价格不能为空"));
      } else if (value == 0) {
        callback(new Error("市场价格必须大于零"));
      } else if (!RegExp.money.test(value) || Math.abs(value) != value || parseInt(value) > 10000000) {
        callback(new Error("请输入1千万以内的正数，最多2位小数"));
      } else if (parseFloat(value) < parseFloat(this.baseInfoForm.cost)) {
        callback(new Error("市场价格必须大于等于成本价格"));
      } else {
        callback();
      }
    };

    const checkPrice = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("会员价格不能为空"));
      }
      setTimeout(() => {
        if (!RegExp.money.test(value) || Math.abs(value) != value || parseInt(value) > 10000000) {
          callback(new Error("请输入1千万以内的正数，最多2位小数"));
        } else if (value == 0) {
          callback(new Error("会员价格必须大于零"));
        } else if (parseFloat(value) > 99999999) {
          callback(new Error("价格设置超过上限值"));
        } else {
          callback();
        }
      }, 1000);
    };
    // 成本价 - > 供货价
    const checkCost = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("成本价格不能为空"));
      } else if (value == 0) {
        callback(new Error("成本价格必须大于零"));
      }
      if (!RegExp.money.test(value) || Math.abs(value) != value || parseInt(value) > 10000000) {
        callback(new Error("请输入1千万以内的正数，最多2位小数"));
      } else {
        if (parseFloat(value) > parseFloat(this.baseInfoForm.mktprice)) {
          callback(new Error("市场价格必须大于等于成本价格"));
        } else {
          callback();
        }
      }
    };
    //  集采价
    const checkOriginal = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("集采价格不能为空"));
      }
      setTimeout(() => {
        if (!RegExp.money.test(value) || Math.abs(value) != value || parseInt(value) > 10000000) {
          callback(new Error("请输入1千万以内的正数，最多2位小数"));
        } else if (value == 0) {
          callback(new Error("集采价格必须大于零"));
        } else if (parseFloat(value) > 99999999) {
          callback(new Error("价格设置超过上限值"));
        } else if (parseFloat(value) > parseFloat(this.baseInfoForm.cost)) {
          callback(new Error("集采价格必须小于等于成本价"));
        } else {
          callback();
        }
      }, 1000);
    };

    const checkWeight = (rule, value, callback) => {
      if (!value) {
        callback(new Error("重量不能为空"));
      } else if (value <= 0) {
        callback(new Error("重量必须大于零"));
      } else if (!RegExp.three.test(value)) {
        callback(new Error("请输三位小数"));
      } else {
        callback();
      }
    };

    const checkTplId = (rule, value, callback) => {
      if (this.baseInfoForm.goods_transfee_charge === 0 && !value) {
        return callback(new Error("请选择一种模板"));
      } else {
        callback();
      }
    };

    // 总库存
    const checkQuantity = (rule, value, callback) => {
      if (!value && value != 0) {
        return callback(new Error("请填写总库存"));
      }
      setTimeout(() => {
        if (!RegExp.integer0.test(value)) {
          callback(new Error("请输入正整数"));
        } else {
          if (parseInt(value) > 99999999) {
            callback(new Error("总库存不得大于99999999"));
          } else {
            callback();
          }
        }
      }, 300);
    };

    const checkExchangePoint = (rule, value, callback) => {
      if (
        this.baseInfoForm.exchange.enable_exchange &&
        !this.baseInfoForm.exchange.exchange_point
      ) {
        return callback(new Error("积分值不能为空且不能为0"));
      }
      setTimeout(() => {
        if (!RegExp.integer.test(this.baseInfoForm.exchange.exchange_point)) {
          callback(new Error("请输入正整数"));
        } else {
          if (parseInt(this.baseInfoForm.exchange.exchange_point) <= 0) {
            callback(new Error("请输入大于0的积分值"));
          } else {
            callback();
          }
        }
      }, 1000);
    };

    const checkExchangeMoney = (rule, value, callback) => {
      if (
        this.baseInfoForm.exchange.exchange_money !== 0 &&
        !this.baseInfoForm.exchange.exchange_money
      ) {
        return callback(new Error("积分所需金额不能为空"));
      }
      setTimeout(() => {
        if (!RegExp.money.test(this.baseInfoForm.exchange.exchange_money)) {
          callback(new Error("请输入正确的金额"));
        } else {
          callback();
        }
      }, 1000);
    };

    const checkGoodsGallery = (rule, value, callback) => {
      setTimeout(() => {
        if (!this.baseInfoForm.goods_gallery) {
          callback(new Error("请选择商品相册"));
        } else {
          callback();
        }
      }, 1000);
    };

    return {
      sku_goods_list: [],//编辑多规格的商品集合
      originTable: {
        cost: '',//供货价
        mktprice: '',//市场价
        original_price: '',//集采价
        quantity: 0,//库存
        ship_regions: '',//配送区域
        ship_regions_ids: ''
      },
      showSpecifications: false,//是否显示规格
      needpay: false,
      // 配送区域弹窗开关
      selectDeliveryArea: $xDialog.create({
        title: "选择配送区域",
        width: "50vw",
        disableCancel: true,
        disabled: true
      }),
      goodsper: false,

      /** 加载中。。。 */
      loading: false,

      /** 当前状态/模式 默认发布商品0 编辑商品1 编辑草稿箱商品2 */
      currentStatus: 0,

      /** 当前激活步骤*/
      activestep: 0,

      /** 当前点击的1级分类索引*/
      activeCategoryIndex1: -1,

      /** 当前点击的2级分类索引*/
      activeCategoryIndex2: -1,

      /** 当前点击的3级分类索引*/
      activeCategoryIndex3: -1,

      /** 当前商品分类名称1*/
      activeCategoryName1: "",

      /** 当前商品分类名称2*/
      activeCategoryName2: "",

      /** 当前商品分类名称3*/
      activeCategoryName3: "",

      /** 当前商品id*/
      activeGoodsId: "",

      /** 1级分类列表*/
      categoryListLevel1: [],

      /** 2级分类列表*/
      categoryListLevel2: [],

      /** 3级分类列表*/
      categoryListLevel3: [],

      /** 商品详情信息提交表单 */
      baseInfoForm: {
        is_spec: 0,//是否是多规格
        // 所有配送地区id
        ship_regions_ids: "",
        ship_regions: "",
        /** 品牌id */
        brand_id: "",

        /** 商城分类id */
        category_id: 0,

        /** 商城分类 名称 */
        category_name: "",

        /** 商品名称 */
        goods_name: "",
        /** 商品编号 sn*/
        // sn: '',

        /** 会员价格 */
        price: "",

        /** 市场价格 */
        mktprice: "",

        /** 成本价格 // 成本价 - > 供货价 */
        cost: "",

        /** 集采价 */
        original_price: "",

        /** 商品重量 */
        weight: 0,

        /** 商品相册列表 */
        goods_gallery_list: [],

        /** 用来校验的商品相册 */
        goods_gallery: "",

        /** 商品总库存 */
        // quantity: 0,

        /** 卖家承担运费1 买家承担运费0 */
        goods_transfee_charge: 1,

        /** sku数据变化或者组合变化判断 0:没变化，1：变化 */
        has_changed: 0,

        /** 是否上架，1上架 0下架 */
        market_enable: 1,

        /** 模板运费id */
        template_id: "",

        /** 积分兑换 */
        // exchange: {
        //   /** 积分兑换所属分类 */
        //   category_id: "",

        //   /** 是否允许积分兑换  1是 0否*/
        //   enable_exchange: 1,

        //   /** 兑换所需金额 */
        //   exchange_money: 0,

        //   /** 积分兑换使用的积分 */
        //   exchange_point: 0
        // },

        /** 店铺分组id*/
        shop_cat_id: 0,

        /** seo描述 */
        meta_description: "",

        /** seo关键字 */
        meta_keywords: "",

        /** seo标题 */
        page_title: "",

        /** 商品参数列表 */
        goods_params_list: [],

        /** sku列表 */
        sku_list: [],

        /** 商品描述 详情 */
        intro: "",

        /** skuid  */

        sku_id: ""
      },

      /** 临时存储商品相册信息 */
      goodsGalleryList: [],

      /** 用来向组件中传递的数据 */
      skuList: [],

      /** 是否自动生成货号 */
      productSn: false,

      /** 总库存是否可编辑 默认可以 false可编辑 true不可编辑 */
      isEditQuantity: false,

      /** 请求的商品参数组列表 */
      goodsParams: [
        {
          group_id: "",
          group_name: "",
          params: [
            {
              param_id: 0,
              param_name: "",
              param_type: 1,
              param_value: "",
              required: 0,
              option_list: [
                { value: 1, label: "" },
                { value: 2, label: "" }
              ]
            }
          ]
        }
      ],

      /** */
      collapseVal: [],

      /** 品牌列表 */
      brandList: [],

      /** 运费模板列表 */
      tplList: [],

      /** 积分商品列表 */
      exchangeGoodsCatrgoryList: [],

      /** 商品图片放大地址 */
      dialogImageUrl: "",

      /** 是否显示放大图片弹框 */
      dialogImage: false,

      /** 是否显示积分配置*/
      isShowExchangeConfig: false,

      /** 商品详情的校验规则 */
      baseInfoFormRule: {
        ship_regions: [
          { required: true, message: "请设置配送区域", trigger: "blur" }
        ],
        goods_name: [
          { required: true, message: "请输入商品名称", trigger: "blur" },
          {
            whitespace: true,
            message: "商品名称不可为纯空格",
            trigger: "blur"
          },
          { min: 3, max: 255, message: "长度在 3 到 255 个字符", trigger: "blur" }
        ],
        // sn: [
        // 	{ required: true, message: '请输入商品编号', trigger: 'blur' },
        // 	{ validator: checkSn, trigger: 'blur' }
        // ],
        mktprice: [
          { required: true, message: "请输入市场价格", trigger: "blur" },
          { validator: checkMarket, trigger: "blur" }
        ],
        // price: [
        //   { required: true, message: "请输入会员价格", trigger: "blur" },
        //   { validator: checkPrice, trigger: "blur" }
        // ],
        cost: [
          // 成本价 - > 供货价
          { required: true, message: "请输入成本价格", trigger: "blur" },
          { validator: checkCost, trigger: "blur" }
        ],
        original_price: [
          { required: true, message: "请输入集采价格", trigger: "blur" },
          { validator: checkOriginal, trigger: "blur" }
        ],
        weight: [
          { required: true, message: "请输入商品重量", trigger: "blur" },
          { validator: checkWeight, trigger: "blur" }
        ],
        goods_gallery: [
          { required: true, validator: checkGoodsGallery, trigger: "change" }
        ],
        quantity: [
          { required: true, message: "请填写总库存", trigger: "blur" },
          { validator: checkQuantity, trigger: "blur" }
        ],
        template_id: [{ validator: checkTplId, trigger: "blur" }],
        exchange_money: [{ validator: checkExchangeMoney, trigger: "blur" }],
        exchange_point: [{ validator: checkExchangePoint, trigger: "blur" }]
      },
      createGoodsButtonLoading: false,
      // 省级地区列表
      areaData: [[], [], []],
      // 树形地区选择器源数据
      fromData: {},
      // 树形地区选择器标题
      title: ["可选省、市、县", "已选省、市、县"],
      // 树形地区选择器目标数据
      toData: {},
      // 树形地区选择器参数配置
      defaultProps: {
        label: "local_name",
        children: "children"
      },
      // 选中一级数
      checkedNodesSum: 0,
      // 选中三级数
      checkedNodesNum: 0,
      // 选中数组
      checkedNodesList: [],
      // 总数组
      totalData: [],
      // 全选开关
      checkedAll: false,
      special1: [],
      special2: []
      // special1: ["台湾", "钓鱼岛"],
      // special2: ["香港特别行政区", "澳门特别行政区"]
    };
  },
  beforeRouteUpdate (to, from, next) {
    if (to.params && to.params.goodsid) {
      this.currentStatus = parseInt(to.params.isdraft) || 0;
      this.activeGoodsId = to.params.goodsid;
      this.activestep = to.params.isdraft ? 1 : 0;
      if (this.currentStatus === 1 && to.params.goodsid) {
        this.GET_GoodData();
      } else if (this.currentStatus === 2 && to.params.goodsid) {
        this.GET_GoodDraftData();
      }
    } else {
      this.GET_NextLevelCategory();
    }
    next();
  },
  mounted () {

    // this.needpay =
    //   this.shopInfo &&
    //   this.shopInfo.shop_type == 2 &&
    //   this.shopInfo.retention_money <= 0;
    API_goods.getretentionMoney().then(res => {
      this.needpay = !res; // res===true 为已通过    不需要缴纳

      this.goodsper = !this.needpay;

      if (this.$route.params && this.$route.params.goodsid) {
        this.originTable = {
          cost: '',//供货价
          mktprice: '',//市场价
          original_price: '',//集采价
          quantity: 0,//库存
          ship_regions: '',//配送区域
          ship_regions_ids: '',
          goods_id: '',
          sn: ''
        }
        this.currentStatus = parseInt(this.$route.params.isdraft) || 0;
        this.activeGoodsId = this.$route.params.goodsid;
        this.activestep = this.$route.params.isdraft ? 1 : 0;
        if (this.currentStatus === 1 && this.$route.params.goodsid) {
          this.GET_GoodData();
        } else if (this.currentStatus === 2 && this.$route.params.goodsid) {
          this.GET_GoodDraftData();
        }
      } else {
        this.GET_NextLevelCategory();
      }
    });
    // 获取区域配送数据
    API_express.getAreaList().then(response => {
      const num = response.length;
      this.totalData = [...response];
      this.areaData[0] = response.splice(0, Math.ceil(num / 3));
      this.areaData[1] = response.splice(0, Math.ceil(num / 3));
      this.areaData[2] = response.splice(0);
      this.$forceUpdate();
    });
  },
  methods: {
    set_showSpecifications (boolean) {//更改是否显示多规格
      this.showSpecifications = boolean;
    },
    // 配送区域设置
    setDistributionArea () {
      this.selectDeliveryArea.display();
      if (this.baseInfoForm.ship_regions_ids.length === 0) return;
      // 渲染配送区域数据
      setTimeout(() => {
        this.areaData.forEach((elem, index) => {
          this.$refs.tree[index].setCheckedKeys(
            this.baseInfoForm.ship_regions_ids.split(",")
          );
        });
      }, 500);
    },
    // 渲染区域配送组件
    renderContent (h, { node, data, store }) {
      let childrenChecked = 0;
      let childrenSum = 0;
      if (node.level === 1) {
        if (this.special1.indexOf(node.data.local_name) !== -1) {
          childrenSum++;
          if (node.checked) {
            childrenChecked++;
          }
        }
        node.childNodes.forEach(elem => {
          if (this.special2.indexOf(elem.data.local_name) !== -1) {
            childrenSum++;
            if (elem.checked) {
              childrenChecked++;
            }
          }
          elem.childNodes.forEach(elem1 => {
            childrenSum++;
            if (elem1.checked) {
              childrenChecked++;
            }
          });
        });
      }
      if (node.level === 2) {
        if (this.special2.indexOf(node.data.local_name) !== -1) {
          childrenSum++;
          if (node.checked) {
            childrenChecked++;
          }
        }
        node.childNodes.forEach(elem => {
          childrenSum++;
          if (elem.checked) {
            childrenChecked++;
          }
        });
      }
      data.childrenSum = childrenSum;
      data.childrenChecked = childrenChecked;
      return (
        <span>
          <span>{data.local_name}</span>
          <span style="color:#5688d9">
            {childrenChecked === 0 ? "" : `（${childrenChecked}）`}
          </span>
        </span>
      );
    },
    // 选中状态变化
    handleCheckChange (data, checked, indeterminate) {
      // return
      this.checkedNodesList = [];
      this.areaData.forEach((elem, index) => {
        this.checkedNodesList = this.checkedNodesList.concat(
          this.$refs.tree[index].getCheckedNodes()
        );
      });
      this.checkedNodesSum = 0;
      this.checkedNodesNum = 0;
      this.checkedNodesList.forEach(elem => {
        if (elem.level === 1) {
          this.checkedNodesSum++;
          if (this.special1.indexOf(elem.local_name) !== -1) {
            this.checkedNodesNum++;
          }
        }
        if (elem.level === 2 && this.special2.indexOf(elem.local_name) !== -1) {
          this.checkedNodesNum++;
        }
        if (elem.level === 3) {
          this.checkedNodesNum++;
        }
      });
      this.checkedAll =
        this.checkedNodesSum === this.totalData.length ? true : false;
    },
    setCheckedNodes (e) {
      // return
      this.areaData.forEach((elem, index) => {
        if (e) {
          this.$refs.tree[index].setCheckedNodes(this.areaData[index]);
        } else {
          this.$refs.tree[index].setCheckedNodes([]);
        }
      });
    },
    submitDeliveryArea () {
      let str = "";
      let arr = [];
      let ids = "";
      this.checkedNodesList.forEach(item => {
        //配送区域ID，一级、二级配送区域，加是否全选标识 比如，二级配送区域ID是 1120，如果全选就记成1120_1
        if (item.level === 1 || item.level === 2) {
          ids += `${item.id}_1,`;
        } else {
          ids += `${item.id},`;
        }
        if (item.level === 1) {
          arr.push({ ...item, children: [] });
        }
        if (item.level === 2) {
          let flog = false;
          arr.forEach(elem => {
            if (elem.id === item.parent_id) {
              flog = true;
              elem.children.push({ ...item, children: [] });
            }
          });
          if (flog === false) {
            this.totalData.forEach(elem1 => {
              if (elem1.id === item.parent_id) {
                arr.push({ ...elem1, children: [{ ...item, children: [] }] });
              }
            });
          }
        }
        if (item.level === 3) {
          let flog = false;
          let index = 0;
          let index1 = 0;
          arr.forEach((elem, i) => {
            elem.children.forEach((elem2, i1) => {
              if (elem2.id === item.parent_id) {
                index = i;
                index1 = i1;
                flog = true;
                elem2.children.push({ ...item, children: [] });
              }
            });
          });
          if (flog === false) {
            this.totalData.forEach(elem1 => {
              elem1.children.forEach(elem3 => {
                if (elem3.id === item.parent_id) {
                  arr.push({
                    ...elem1,
                    children: [
                      { ...elem3, children: [{ ...item, children: [] }] }
                    ]
                  });
                }
              });
            });
          }
        }
      });
      arr.forEach((elem1, index1) => {
        if (elem1.childrenChecked === elem1.childrenSum) {
          return (str += `<span style="color:red;">${elem1.local_name}</span>;`);
        } else {
          str += `<span style="color:red;">${elem1.local_name}</span> `;
          elem1.children.forEach((elem2, index2) => {
            if (elem2.childrenChecked === elem2.childrenSum) {
              return (str += `${elem2.local_name},`);
            } else {
              str += `${elem2.local_name}（`;
              elem2.children.forEach((elem3, index3) => {
                if (elem2.children.length - 1 === index3) {
                  str += `${elem3.local_name}）`;
                } else {
                  str += `${elem3.local_name},`;
                }
              });
            }
          });
        }
      });
      this.baseInfoForm.ship_regions_ids = ids;
      this.baseInfoForm.ship_regions = str;
      this.selectDeliveryArea.dismiss();
    },

    /** 上一步*/
    pre () {
      this.loading = true;
      if (this.activestep === 1) {
        this.GET_NextLevelCategory();
        if (this.activestep-- < 0) this.activestep = 0;
        this.loading = false;
      } else {
        this.activestep--;
        this.loading = false;
      }
    },

    /** 下一步*/
    next () {
      /** 1级校验 */
      this.loading = true;
      if (this.activestep === 0) {
        if (!this.activeCategoryName1) {
          this.$message.error("请选择商品分类");
          this.loading = false;
          return;
        }
        if (!this.activeCategoryName2) {
          this.$message.error("商品二级分类必选");
          this.loading = false;
          return
        }
        if (!this.activeCategoryName3) {
          this.$message.error("商品三级分类必选");
          this.loading = false;
          return
        }
        /** 获取该商城分类下 商品参数信息 */
        /** 传参数1 表示搜索添加模式（发布商品模式0）下的商品参数信息；  如果不传 表示搜索编辑（包括草稿箱编辑）模式下的商品参数信息 */
        this.GET_GoodsParams(1);
        /** 搜索品牌列表 */
        this.getGoodsBrandList();
        /** 运费模板列表 */
        this.getTplList();
        /** 积分商品分类列表 */
        this.getGoodsCatrgory();
      }

      /** 2级校验 */
      if (this.activestep === 1) {
        this.$refs["baseInfoForm"].validate(valid => {
          if (valid) {
            /** 规格校验 */
            if (!this.skuFormVali()) {
              this.loading = false;
              return;
            }
            this.loading = false;
            if (this.activestep++ > 2) return;
          } else {
            this.loading = false;
            this.$message.error(
              "表单中存在未填写或者填写有误的地方,已有错误标示，请检查并正确填写"
            );
          }
        });
        return;
      }
      /** 下一步 */
      this.loading = false;
      if (this.activestep++ > 2) return;
    },
    //供应暂不缴纳
    noPay () {
      // this.needpay=false;
      // this.goodsper=true;
      //跳转到商品列表
      this.$router.push("/goods/goods-list");
    },
    //供应商跳转质保金页面
    toPay () {
      this.$router.push("/finance/money");
    },
    /** 保存草稿 */
    saveDraft () {
      const _params = this.generateFormData(this.baseInfoForm);
      /** 规格校验 */
      if (!this.skuFormVali(true)) {
        return;
      }
      this.$refs["baseInfoForm"].validate(valid => {
        if (valid) {
          if (this.activeGoodsId) {
            this.activeGoodsId = this.activeGoodsId || 1;
            /** 修改草稿箱商品 */
            API_goods.editDraftGoods(this.activeGoodsId, _params).then(() => {
              this.$message.success("修改草稿箱商品成功");
              this.$router.push({ path: "/goods/draft-list" });
              this.$route.params.callback();
            });
          } else {
            /** 保存草稿操作 */
            API_goods.saveDraft(_params).then(() => {
              this.$message.success("保存草稿成功");
              this.$route.params.callback();
              this.$router.push({ path: "/goods/draft-list" });
            });
          }
        } else {
          this.$message.error(
            "表单中存在未填写或者填写有误的地方,已有错误标示，请检查并正确填写"
          );
        }
      });
    },
    async edit_goodsInfo (formData) {
      //判断是单规格商品编辑还是多规格商品编辑
      if (formData.goods_dtolist.length > 1) {
        for (let i = 0; i < formData.goods_dtolist.length; i++) {
          if (this.sku_goods_list[i] && formData.goods_dtolist[i].goods_id) {
            await API_goods.reserveStockGoods(formData.goods_dtolist[i].goods_id, [
              {
                quantity_count: Number(formData.goods_dtolist[i].quantity),
                sku_id: this.sku_goods_list[i].sku_id
              }
            ])
          }
        }
      } else {
        await API_goods.reserveStockGoods(formData.goods_dtolist[0].goods_id, [
          {
            quantity_count: Number(formData.goods_dtolist[0].quantity),
            sku_id: this.sku_goods_list[0].sku_id
          }
        ])
      }
      formData.goods_dtolist.forEach(li => {// 商品编辑时设置为下架
        li.market_enable = 0
      })
      this.createGoodsButtonLoading = true;
      const delete_goods_id = formData.goods_dtolist.filter(items => items.goods_id).map(items => items.goods_id)
      formData.delete_goods_id = delete_goods_id.toString();
      API_goods.updateSpecGoodseditGoods(this.activeGoodsId, formData)
        .then(() => {
          this.$message.success("修改商品成功");
          this.$store.dispatch("delCurrentViews", {
            view: this.$route,
            $router: this.$router
          });
          this.$router.push({ path: "/goods/goods-list" });
        })
        .finally(() => {
          this.createGoodsButtonLoading = false;
        });
    },
    /**确认创建 上架  */
    aboveGoods () {
      this.createGoodsButtonLoading = true;
      if (this.baseInfoForm.activeGoodsId === 0) {
        this.$message.error("严重错误，商城分类Id不可为0");
        this.createGoodsButtonLoading = false;
        return;
      }
      /** 规格校验 */
      if (!this.skuFormVali()) {
        this.createGoodsButtonLoading = false;
        return;
      }

      let _params = this.generateFormData(this.baseInfoForm);
      /** 如果规格无变化 则传0 有变化传1 */
      if (!_params.has_changed) {
        _params.has_changed = 0;
      }
      /** 上架 market_enable 1上架0下架*/
      _params.market_enable = 1;
      //
      let formData = {
        is_spec: this.baseInfoForm.is_spec == undefined ? 0 : this.baseInfoForm.is_spec,
        goods_dtolist: []
      }
      if (this.baseInfoForm.is_spec) {//多规格商品
        //判断有无新增图片
        this.sku_goods_list.forEach(images => {
          let imageList = JSON.parse(JSON.stringify(_params.goods_gallery_list))
          imageList.forEach(img => {
            const find_img = images.goods_gallery_list.find(fis => fis.original === img.original)
            if (find_img != undefined) {
              img.img_id = find_img.img_id;
            }
          })
          images.goods_gallery_list = imageList
        })
        if (this.activeGoodsId) {//编辑
          _params.sku_list.forEach((items, indexs) => {
            const find_goods = this.sku_goods_list.find(key => key.goods_id == items.goods_id)
            items.sku_id = find_goods ? find_goods.sku_id : null;
            items.price = items.cost
            items.supplier_price = items.cost
            items.sn == 'null' ? items.sn = null : items.sn
            items.goods_id == 'null' ? items.goods_id = null : items.sn
            if (indexs == 0) {//如果是单规格变多规格
              if (this.sku_goods_list.length == 1) {
                items.goods_id = this.sku_goods_list[0].goods_id
                items.sn = this.sku_goods_list[0].sn
                items.sku_id = this.sku_goods_list[0].sku_id
              }
            }
            let objs = {
              ..._params,
              update_goods_name: _params.goods_name,
              goods_id: items.goods_id ? items.goods_id : null,
              sn: items.sn ? items.sn : null,
              goods_gallery_list: this.sku_goods_list[indexs] ? this.sku_goods_list[indexs].goods_gallery_list : this.createdimageList(_params),
              cost: Number(items.cost),//成本价
              mktprice: Number(items.mktprice),//市场价
              original_price: Number(items.original_price),//会员价
              quantity: items.quantity,
              ship_regions: items.ship_regions,
              ship_regions_ids: items.ship_regions_ids,
              sku_list: [items],
              is_show_spec_img: _params.sku_list[0].spec_list[0].is_show_spec_img ? 1 : 0,
            }
            formData.goods_dtolist.push(objs)
          })
        } else {//新增
          _params.sku_list.forEach((items, indexs) => {
            items.supplier_price = items.cost
            items.sku_id = null;
            items.price = items.cost
            let objs = {
              ..._params,
              update_goods_name: _params.goods_name,
              cost: items.cost,
              mktprice: items.mktprice,
              original_price: items.original_price,
              quantity: items.quantity,
              ship_regions: items.ship_regions,
              ship_regions_ids: items.ship_regions_ids,
              sku_list: [items],
              is_show_spec_img: _params.sku_list[0].spec_list[0].is_show_spec_img ? 1 : 0,
            }
            formData.goods_dtolist.push(objs)
          })
        }
      } else {//单规格商品
        _params.quantity = Number(_params.quantity)
        _params.update_goods_name = _params.goods_name
        formData.goods_dtolist.push(_params)
      }
      this.$refs["baseInfoForm"].validate(valid => {
        if (valid) {
          if (this.currentStatus !== 2) {
            if (this.activeGoodsId) {

              this.edit_goodsInfo(formData)
            } else {

              formData.goods_dtolist.forEach(li => {
                li.price = li.cost
              })
              /** 正常商品上架 */
              API_goods.aboveGoods(formData)
                .then(() => {
                  this.$message.success("上架商品成功");
                  this.$store.dispatch("delCurrentViews", {
                    view: this.$route,
                    $router: this.$router
                  });
                  this.$router.push({ path: "/goods/goods-list" });
                })
                .finally(() => {
                  this.createGoodsButtonLoading = false;
                });
            }
          } else {

            API_goods.aboveDraftGoods(this.activeGoodsId, _params)
              .then(() => {
                this.$message.success("上架草稿箱商品成功");
                this.$store.dispatch("delCurrentViews", {
                  view: this.$route,
                  $router: this.$router
                });
                this.$router.push({ path: "/goods/goods-list" });
              })
              .finally(() => {
                this.createGoodsButtonLoading = false;
              });
          }
        } else {
          this.createGoodsButtonLoading = false;
          this.$message.error(
            "存在必填项未填写的内容项，请检查并正确填写"
          );
        }
      });
    },
    createdimageList (data) {
      let imagesult = []
      imagesult = JSON.parse(JSON.stringify(data.goods_gallery_list))
      imagesult.forEach(item => {
        item.img_id = -1;
      })
      return imagesult
    },
    /** 下架*/
    handleUnderGoods () {
      API_goods.underGoods(this.activeGoodsId, {}).then(() => {
        this.$message.success("下架成功");
        this.$store.dispatch("delCurrentViews", {
          view: this.$route,
          $router: this.$router
        });
        this.$router.push({ path: "/goods/goods-list" });
      });
    },

    /** 搜索下一级 商城商品分类*/
    GET_NextLevelCategory (row, level) {
      this.loading = true;
      const _id = row && row.category_id !== 0 ? row.category_id : 0;
      API_goodsCategory.getGoodsCategoryLevelList(_id, {}).then(response => {
        this.loading = false;
        if (level === 1) {
          this.categoryListLevel2 = response;
          this.categoryListLevel3 = [];
        } else if (level === 2) {
          this.categoryListLevel3 = response;
        } else if (!level) {
          this.categoryListLevel1 = response;
        }
      });
    },

    /** 选择商城商品分类 */
    handleSelectCategory (row, index, level) {
      if (level === 1) {
        this.activeCategoryName1 = row.name;
        this.activeCategoryName2 = this.activeCategoryName3 = "";
        this.activeCategoryIndex1 = index;
        this.activeCategoryIndex2 = -1;
        this.activeCategoryIndex3 = -1;
      } else if (level === 2) {
        this.activeCategoryName2 = row.name;
        this.activeCategoryName3 = "";
        this.activeCategoryIndex2 = index;
        this.activeCategoryIndex3 = -1;
      } else {
        this.activeCategoryName3 = row.name;
        this.activeCategoryIndex3 = index;
      }
      // 设置当前商城分类ID
      this.baseInfoForm.category_id = row.category_id;
      this.GET_NextLevelCategory(row, level);
    },

    /** 搜索单个商品信息*/
    GET_GoodData () {
      API_goods.getGoodData(this.activeGoodsId, {}).then(response => {

        response.goods_dolist.forEach(items => {
          items.category_name = response.category_name
          items.spec_list = JSON.parse(items.specs)
          if (items.update_goods_name && items.update_goods_name != '' && items.update_goods_name != null) {
            items.goods_name = items.update_goods_name
          }
        })

        this.sku_goods_list = JSON.parse(JSON.stringify(response.goods_dolist));
        this.skuList = response.goods_dolist.map((key) => {
          if (key && key.spec_list && Array.isArray(key.spec_list)) {
            let { cost, mktprice, original_price, quantity, is_show_spec_img, ship_regions, ship_regions_ids, goods_id, sn } = key;
            const spec_list = key.spec_list.map((item) => {
              let {
                spec_id,
                spec_image,
                spec_type,
                spec_value,
                spec_value_id,
                spec_name,
              } = item;
              return {
                spec_id,
                spec_image,
                spec_type,
                spec_value,
                spec_value_id,
                spec_name,
                is_show_spec_img
              };
            });
            return { cost, mktprice, original_price, quantity, ship_regions, ship_regions_ids, goods_id, sn, spec_list };
          }
        });
        /** 此处完成商品信息赋值 进行判断如果有值的话 */
        this.activeCategoryName1 = response.category_name.split("&gt;")[0];
        this.activeCategoryName2 = response.category_name.split("&gt;")[1];
        this.activeCategoryName3 = response.category_name.split("&gt;")[2];
        this.baseInfoForm = response.goods_dolist[0];
        this.baseInfoForm.goods_gallery_list = response.goods_gallery_list;
        /** 商品相册校验属性 */
        this.baseInfoForm.goods_gallery_list.forEach(key => {
          this.$set(key, "url", key.original);
        });
        this.$nextTick(() => {
          this.setSort();
        });
        this.baseInfoForm.goods_gallery = this.baseInfoForm.goods_gallery_list.toString();
        this.goodsGalleryList = JSON.parse(
          JSON.stringify(this.baseInfoForm.goods_gallery_list)
        );
        /** 商品规格校验属性  */
        if (
          !this.baseInfoForm.sku_list ||
          !Array.isArray(this.baseInfoForm.sku_list)
        ) {
          this.baseInfoForm.sku_list = [];
        }
        /** 积分相关设置 如果没有积分相关则设置为空 */
        if (
          !this.baseInfoForm.exchange ||
          !this.baseInfoForm.exchange.enable_exchange
        ) {
          this.baseInfoForm.exchange = {
            /** 积分兑换所属分类 */
            category_id: "",
            /** 是否允许积分兑换  1是 0否*/
            enable_exchange: 0,
            /** 兑换所需金额 */
            exchange_money: 0,
            /** 积分兑换使用的积分 */
            exchange_point: 0
          };
        } else {
          this.isShowExchangeConfig =
            this.baseInfoForm.exchange.enable_exchange === 1;
        }
        /** 搜索品牌列表 */
        this.getGoodsBrandList();

        /** 运费模板列表 */
        this.getTplList();

        /** 搜索商品参数 */
        this.GET_GoodsParams();

        /** 积分商品分类列表 */
        this.getGoodsCatrgory();

        /** 搜索商品sku信息 */
        // API_goods.getGoodsStockList(this.activeGoodsId, {}).then(response => {
        //   this.baseInfoForm.sku_id = response[0].sku_id;
        //   /** 构造临时规格数据 */
        //   this.skuList = response.map(key => {
        //     if (key && key.spec_list && Array.isArray(key.spec_list)) {
        //       const spec_list = key.spec_list.map(item => {
        //         let {
        //           spec_id,
        //           spec_image,
        //           spec_type,
        //           spec_value,
        //           spec_value_id,
        //           spec_name
        //         } = item;
        //         return {
        //           spec_id,
        //           spec_image,
        //           spec_type,
        //           spec_value,
        //           spec_value_id,
        //           spec_name
        //         };
        //       });
        //       let { cost, quantity, sn, weight, sku_id } = key;
        //       // const price = key.price
        //       const price = key.supplier_price;
        //       return { cost, price, quantity, sn, weight, sku_id, spec_list };
        //     }
        //   });

        // });
      });
    },

    /** 搜索商品参数 */
    GET_GoodsParams (next = null) {
      if (this.activeGoodsId && !next) {
        API_goods.getEditGoodsParams(
          this.baseInfoForm.category_id,
          // this.baseInfoForm.category_id,
          this.activeGoodsId
        ).then(response => {
          this.loading = false;
          this.goodsParams = response;
          this.collapseVal = this.goodsParams.map(key => {
            if (key.group_id) {
              return key.group_id;
            }
          });
          if (!response || response.length <= 0) {
            return;
          }
          this.baseInfoForm.goods_params_list = [];
          this.goodsParams.forEach(key => {
            if (key && key.params) {
              key.params.forEach(elem => {
                this.baseInfoForm.goods_params_list.push(elem);
              });
            }
          });
        });
      } else {
        API_goods.getGoodsParams(this.baseInfoForm.category_id).then(
          response => {
            this.loading = false;
            this.goodsParams = response;
            this.collapseVal = this.goodsParams.map(key => {
              if (key.group_id) {
                return key.group_id;
              }
            });
            if (!response || response.length <= 0) {
              return;
            }
            this.baseInfoForm.goods_params_list = [];
            this.goodsParams.forEach(key => {
              if (key && key.params) {
                key.params.forEach(elem => {
                  this.baseInfoForm.goods_params_list.push(elem);
                });
              }
            });
          }
        );
      }
    },

    /** 搜索单个草稿箱商品信息 */
    GET_GoodDraftData () {
      API_goods.getGoodDraftData(this.activeGoodsId, {}).then(response => {
        /** 此处完成商品信息赋值 进行判断如果有值的话 */
        this.baseInfoForm = { ...response };
        /** 积分商品分类列表 */
        this.getGoodsCatrgory();
        /** 积分相关设置 如果没有积分相关则设置为空 */
        if (
          this.baseInfoForm.exchange_category_id ||
          this.baseInfoForm.goods_type === "POINT"
        ) {
          this.$set(this.baseInfoForm, "exchange", {
            category_id: response.exchange_category_id,

            enable_exchange: response.goods_type === "POINT" ? 1 : 0,

            exchange_money: response.exchange_money,

            exchange_point: response.exchange_point
          });
          this.isShowExchangeConfig =
            this.baseInfoForm.exchange.enable_exchange === 1;
        }
        /** 商品相册校验属性 */
        if (
          Array.isArray(response.gallery_list) &&
          response.gallery_list.length
        ) {
          this.baseInfoForm.goods_gallery_list = response.gallery_list.map(
            key => {
              return {
                img_id: -1,
                url: key,
                original: key,
                sort: 0
              };
            }
          );
          this.$nextTick(() => {
            this.setSort();
          });
          this.baseInfoForm.goods_gallery = this.baseInfoForm.goods_gallery_list.toString();
        }
        if (!this.baseInfoForm.goods_gallery_list)
          this.baseInfoForm.goods_gallery_list = [];
        this.goodsGalleryList = JSON.parse(
          JSON.stringify(this.baseInfoForm.goods_gallery_list)
        );
        /** 商品规格校验属性  */
        if (!this.baseInfoForm.sku_list) {
          this.baseInfoForm.sku_list = [];
        }
        /** 搜索品牌列表 */
        this.getGoodsBrandList();

        /** 运费模板列表 */
        this.getTplList();

        /** 搜索草稿箱商品参数信息 */
        this.GET_GoodsDtagtParams();

        /** 搜索草稿箱sku信息 */
        API_goods.draftSku(this.activeGoodsId, {}).then(response => {
          /** 构造临时规格数据 */
          this.skuList = response.map(key => {
            if (key && key.spec_list && Array.isArray(key.spec_list)) {
              const spec_list = key.spec_list.map(item => {
                let {
                  spec_id,
                  spec_image,
                  spec_type,
                  spec_value,
                  spec_value_id,
                  spec_name
                } = item;
                return {
                  spec_id,
                  spec_image,
                  spec_type,
                  spec_value,
                  spec_value_id,
                  spec_name
                };
              });
              let { cost, quantity, sn, weight } = key;
              const price = key.price;
              return { cost, price, quantity, sn, weight, spec_list };
            }
          });
        });
      });
    },

    /** 搜索草稿箱商品参数信息 */
    GET_GoodsDtagtParams () {
      // 处理数据 方便校验
      const goods_id = this.activeGoodsId || 1;
      API_goods.getGoodsDraftParams(goods_id).then(response => {
        this.loading = false;
        if (!response || response.length <= 0) {
          return;
        }
        this.goodsParams = response;
        this.collapseVal = this.goodsParams.map(key => {
          if (key.group_id) {
            return key.group_id;
          }
        });
        this.baseInfoForm.goods_params_list = [];
        this.goodsParams.forEach(key => {
          if (key && key.params) {
            key.params.forEach(elem => {
              this.baseInfoForm.goods_params_list.push(elem);
            });
          }
        });
      });
    },

    /** 商品分组组件 改变时触发 */
    changeGoodsCateGory (val) {
      this.baseInfoForm.shop_cat_id = val[val.length - 1];
    },

    /** 搜索商品品牌列表 */
    getGoodsBrandList () {

      let category_id;
      if (this.baseInfoForm.category_id) {
        category_id = this.baseInfoForm.category_id
      }
      API_goods.getGoodsBrandList(category_id, {}).then(
        response => {
          this.brandList = response;
        }
      );
    },

    /** 商品品牌 改变时触发 */
    changeGoodsBrand (val) {
      this.baseInfoForm.brand_id = val;
    },

    /** 运费模板列表 */
    getTplList () {
      API_goods.getTplList(this.activeGoodsId, {}).then(response => {
        this.tplList = response;
      });
    },

    /** 选中值卖家运费/买家运费时出发 */
    changeTplItem (val) {
      if (this.tplList[0] && this.tplList[0].id) {
        this.baseInfoForm.template_id = val === 0 ? this.tplList[0].id : "";
      }
    },

    /** 运费模板改变时触发 */
    changeTpl (val) {
      this.baseInfoForm.template_id =
        this.baseInfoForm.goods_transfee_charge === 0 ? val : "";
    },

    /** 积分商品商城分类列表 */
    getGoodsCatrgory () {
      API_goods.getExchangeCatsList(0).then(response => {
        this.exchangeGoodsCatrgoryList = response;
      });
    },

    /** 积分兑换开关值改变时触发 */
    handleSwitchexchange (val) {
      this.isShowExchangeConfig = val;
      if (val) {
        this.baseInfoForm.exchange.enable_exchange = 1;
      } else {
        this.baseInfoForm.exchange.enable_exchange = 0;
      }
    },

    /** 积分商品商城分类 改变时触发*/
    changeExangeCategory (val) {
      this.baseInfoForm.exchange.category_id = val;
    },
    /** 文件列表  图片个数 校验*/
    handleExceed (files, fileList) {
      this.$message.warning(`最多上传5张图`);
    },
    /** 文件列表移除文件时的钩子  图片删除校验*/
    handleRemove (file, fileList) {
      this.baseInfoForm.goods_gallery_list.forEach((key, index) => {
        if (key.img_id !== -1) {
          if (key.img_id === file.img_id) {
            this.baseInfoForm.goods_gallery_list.splice(index, 1);
          }
        } else {
          if (key.name === file.response.name) {
            this.baseInfoForm.goods_gallery_list.splice(index, 1);
          }
        }
      });
      if (fileList.length <= 0) {
        this.baseInfoForm.goods_gallery_list = [];
        this.baseInfoForm.goods_gallery = this.baseInfoForm.goods_gallery_list.toString();
      }
      this.$refs.baseInfoForm.validateField("goods_gallery");
    },

    /** 文件列表上传成功时的钩子  上传成功校验 */
    handleSuccess (response, file, fileList) {
      // this.baseInfoForm.goods_gallery_list.push({
      //   img_id: -1,

      //   original: response.url,

      //   url: response.url,

      //   sort: 0,

      //   name: response.name
      // });
      this.baseInfoForm.goods_gallery_list = fileList.map(items => {
        return {
          img_id: -1,
          original: items.response.url,
          url: items.response.url,
          sort: 0,
          name: items.response.name
        }
      })
      this.baseInfoForm.goods_gallery = JSON.stringify(this.baseInfoForm.goods_gallery_list);
      this.$refs["baseInfoForm"].validateField("goods_gallery");
      this.$nextTick(() => {
        this.setSort();
      });
    },

    /** 点击已上传的文件链接时的钩子 放大 */
    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url;
      this.dialogImage = true;
    },

    /** 拖拽改变图片顺序 */
    setSort () {
      const el = document.querySelectorAll(
        "div.avatar-uploader > ul.el-upload-list--picture-card"
      )[0];
      this.sortable = Sortable.create(el, {
        ghostClass: "sortable-ghost",
        setData: function (dataTransfer) { },
        onEnd: evt => {
          let temp = this.baseInfoForm.goods_gallery_list[evt.oldIndex];
          this.baseInfoForm.goods_gallery_list[
            evt.oldIndex
          ] = this.baseInfoForm.goods_gallery_list[evt.newIndex];
          this.baseInfoForm.goods_gallery_list[evt.newIndex] = temp;
        }
      });
    },

    /** 图片上传之前的校验  */
    beforeAvatarUpload (file) {
      const isType =
        file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/png";
      const isLt1M = file.size / 1024 / 1024 < 1;

      if (!isType) {
        this.$message.error("上传头像图片只能是 JPG/JPEG/PNG 格式!");
      }
      if (!isLt1M) {
        this.$message.error("上传商品相册图片大小不能超过 1MB!");
      }

      /** 重复校验*/
      const isExit = this.baseInfoForm.goods_gallery_list.some(key => {
        return file.name === key.name;
      });
      if (isExit) {
        this.$message.error("图片已存在");
      }

      return isType && isLt1M && !isExit;
    },

    /** 规格选择器规格数据改变时触发 */
    finalSku (val) {

      this.isEditQuantity = !!val.length;
      /** 动态修改总库存 每次设置为0  此处每次进行循环计算 存在性能浪费 */
      // this.baseInfoForm.quantity = 0;
      // val.forEach(key => {
      //   if (key.quantity) {
      //     this.baseInfoForm.quantity += parseInt(key.quantity);
      //   }
      // });
      /** 删除 因为对象浅拷贝造成的字段冗余（不必要的字段）*/
      val.forEach(key => {
        key.spec_list.forEach(item => {
          // delete item.cost;
          delete item.price;
          // delete item.quantity;
          // delete item.sn;
          delete item.weight;
        });
      });
      this.baseInfoForm.sku_list = val;
      /** 规格数据是否存在变化 */
      this.baseInfoForm.has_changed = 1;
      this.baseInfoForm.sku_list.length > 0 ? (this.baseInfoForm.is_spec = 1) : (this.baseInfoForm.is_spec = 0);

    },

    /** 构造表单数据 */
    generateFormData (data) {
      let _params = {
        ...data
      };
      // 删除不必要的表单数据
      delete _params.original;
      delete _params.exchange_category_id;
      delete _params.exchange_money;
      delete _params.exchange_point;
      delete _params.gallery_list;
      delete _params.goods_gallery;
      if (_params.goods_gallery_list) {
        _params.goods_gallery_list.forEach(key => {
          delete key.name;
          delete key.big;
          delete key.goods_id;
          delete key.isdefault;
          delete key.small;
          delete key.status;
          delete key.thumbnail;
          delete key.tiny;
          delete key.uid;
          delete key.url;
        });
      }
      /** 积分商品数据 */
      // if (!this.isShowExchangeConfig) {
      //   _params.goods_type = "NORMAL";
      //   _params.exchange.enable_exchange = 0;
      // } else {
      //   _params.goods_type = "POINT";
      //   _params.exchange.exchange_money = parseFloat(
      //     _params.exchange.exchange_money
      //   );
      // }
      /** 运费模板 */
      _params.template_id = _params.template_id || 0;
      /** 处理UE的信息 */
      if (this.activestep === 1) {
        _params.intro = this.$refs["ue"].getUEContent();
      }
      return _params;
    },

    /** 商品规格选择器校验 */
    skuFormVali (isDraft) {
      /** 如果并未选择规格 */
      if (
        Array.isArray(this.baseInfoForm.sku_list) &&
        this.baseInfoForm.sku_list.length === 0
      ) {
        return true;
      }
      this.productSn = false;
      /** 是否自动生成货号校验 检测是否所有的货号都存在*/
      // const _sn = this.baseInfoForm.sku_list.some(key => {
      // 	return key.sn === ''
      // })
      // if (_sn) {
      // 	this.$confirm('确认自动生成货号, 是否继续?', '提示').then(() => {
      // 		this.productSn = true
      // 	})
      // 	return false
      // }
      /** 规格值空校验 */
      let _result = false;
      this.baseInfoForm.sku_list.forEach(key => {
        // delete key.sn;
        key.weight = 0;
        key.sn == '' ? key.sn = 'null' : ''
        key.goods_id == '' ? key.goods_id = 'null' : ''
        Object.values(key).forEach(item => {
          if (!item && item !== 0) {
            _result = true;
          }
        });
      });
      if (_result) {
        this.$message.error("存在必填项未填写的内容项，请检查并正确填写");
        return false;
      }
      // 规格值校验
      let spec_fun = false;
      let spec_tip;
      this.baseInfoForm.sku_list.forEach(key => {
        if (!RegExp.money.test(key.cost)) {
          spec_fun = true;
          // 成本价 - > 供货价
          spec_tip = "请输入正确的供货价金额";
        }
        // if (!RegExp.money.test(key.price)) {
        //   spec_fun = true;
        //   spec_tip = "请输入正确的价格";
        // }
        if (!(parseInt(key.weight) >= 0 && parseInt(key.weight) <= 99999999)) {
          spec_fun = true;
          spec_tip = "重量须为0 - 99999999之间的整数";
        }
        if (
          !(parseInt(key.quantity) >= 0 && parseInt(key.quantity) <= 99999999) ||
          !/^[0-9]\d*$/.test(key.quantity)
        ) {
          spec_fun = true;
          spec_tip = "库存须为0 - 99999999之间的整数";
        }
      });
      if (spec_fun) {
        this.$message.error(spec_tip);
        return false;
      }
      if (isDraft) {
        this.productSn = true;
        return true;
      }
      return true;
    }
  }
};
</script>

<style lang="scss" scoped>
.displayBoard {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  border: 1px solid #d4d4d4;
  padding: 0 10px;
  overflow: auto;
}

.el-tree-box {
  margin-bottom: 10px;
  max-height: 500px;
  display: flex;
  justify-content: space-between;
  overflow-y: auto;
}

.el-tree-bottom {
  display: flex;
  justify-content: space-between;
}

/*选择商品品类*/
.content-goods-publish {
  padding: 15px;
  margin: 0 auto;
  text-align: center;
  border: 1px solid #ddd;
  background: none repeat 0 0 #fff;
  border-radius: 5px;

  /*商品品类*/
  .goods-category {
    text-align: left;
    padding: 10px;
    background: #fafafa;
    border: 1px solid #e6e6e6;

    ul {
      padding: 8px 4px 8px 8px;
      list-style: none;
      width: 300px;
      background: none repeat 0 0 #fff;
      border: 1px solid #e6e6e6;
      display: inline-block;
      letter-spacing: normal;
      margin-right: 15px;
      vertical-align: top;
      word-spacing: normal;
      overflow-x: scroll;
      height: calc(100vh - 300px);

      li {
        line-height: 20px;
        padding: 5px;
        cursor: pointer;
        color: #333;
        font-size: 12px;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
    }
  }

  /** 当前品类被选中的样式 */
  .activeClass {
    background-color: #d9edf7;
    border: 1px solid #bce8f1;
    color: #3a87ad;
  }

  /*!*当前选择的商品品类文字*!*/
  .current-goods-category {
    text-align: left;
    padding: 10px;
    width: 100%;
    border: 1px solid #fbeed5;
    color: #c09853;
    background-color: #fcf8e3;
    margin: 10px auto;
    padding: 8px 35px 8px 14px;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
    font-size: 12px;
    font-weight: bold;
  }
}

/*编辑基本信息*/
.el-form {
  padding-bottom: 80px;

  .el-form-item {
    width: 100%;
    text-align: left;
  }
}

/*平铺*/
div.base-info-item>div {
  margin-left: 5%;
}

div.base-info-item {
  h4 {
    margin-bottom: 10px;
    padding: 0 10px;
    border: 1px solid #ddd;
    background-color: #f8f8f8;
    font-weight: bold;
    color: #333;
    font-size: 14px;
    line-height: 40px;
    text-align: left;
  }

  /** 审核信息-拒绝原因 */
  .auth-info {
    color: red;
  }

  .el-form-item {
    width: 30%;
    min-width: 500px;
  }

  .goods-name-width {
    width: 50%;
    min-width: 300px;
  }

  .el-form-item__content {
    margin-left: 120px;
    text-align: left;
  }

  p.goods-group-manager {
    padding-left: 7.5%;
    text-align: left;
    color: #999;
    font-size: 13px;
  }

  /*teatarea*/
  /deep/ .el-textarea {
    width: 150%;
  }

  .seo-text {
    width: 150%;
  }
}

/*折叠面板*/
.el-collapse-item {
  /deep/ .el-collapse-item__header {
    text-align: left;
    background-color: #f8f8f8;
    padding: 0 10px;
    font-weight: bold;
    color: #333;
    font-size: 14px;
  }

  .el-form-item {
    margin-left: 5%;
    width: 25%;
  }

  /deep/ .el-form-item__content {
    margin-left: 120px;
    text-align: left;
  }

  p.goods-group-manager {
    padding-left: 12%;
    text-align: left;
    color: #999;
  }

  /deep/ .el-collapse-item__content {
    padding: 10px 0;
    text-align: left;
  }
}

/*商品描述*/
.goods-intro {
  * {
    line-height: 1;
  }

  /deep/ .edui-editor {
    width: 100% !important;
  }
}

/** 底部步骤 */
.footer {
  // width: calc(100% - 280px);
  padding: 10px;
  background-color: #fff;
  position: fixed;
  bottom: 0px;
  // left: 280px;
  text-align: center;
  z-index: 999;
  border-top: 1px solid #eee;
  left: 13.2%;
  right: 2%;
}

/*图片上传组件第一张图设置封面*/
.goods-images {
  /deep/ li.el-upload-list__item:first-child {
    position: relative;
  }

  /deep/ li.el-upload-list__item:first-child:after {
    content: "封";
    color: #fff;
    font-weight: bold;
    font-size: 12px;
    position: absolute;
    left: -15px;
    top: -6px;
    width: 40px;
    height: 24px;
    padding-top: 6px;
    background: #13ce66;
    text-align: center;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-box-shadow: 0 0 1pc 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 1pc 1px rgba(0, 0, 0, 0.2);
  }
}
</style>
